<template>
<!-- eslint-disable -->
	<div>
		<h1 class="text-center my-6">ユーザ情報編集</h1>
		<div>
			<h3 style="margin: 40px 0 16px 24px">＊アイコン</h3>
			<div class="ml-10">
				<label>ファイルを選択する</label>
				<v-file-input
				v-model="icon"
				style="max-width: 300px;"
				></v-file-input>
			</div>
		</div>

		<div>
			<h3 style="margin: 40px 0 16px 24px">＊非表示リスト</h3>
			<div >
				<label class="ml-10">外すユーザーを選択する</label>
				<v-autocomplete
					v-model="removeList"
					v-if="hideList[0] != ''"
					:items="hideList"
					color="white"
					hide-no-data
					multiple
					label="対象ユーザー"
					class="mt-4"
					style="max-width: 250px; margin-left: 75px;"
				></v-autocomplete>
			</div>
		</div>

		<v-btn
			color="secondary"
			v-on:click="upload()"
			style="width:100px; margin: 50px 0 0 145px;"
		>
		登録
		</v-btn>

	</div>
</template>

<script>
/*eslint-disable */
import axios from "axios"
import * as api from '@/api.js'
import BirthdayPicker from '@/components/BirthdayPicker.vue'
export default {
	name: 'Setting',
	components: { BirthdayPicker },
	data() {
		return {
			birth: '',
			icon: [],
			hideList: [],
			removeList: [],
		};
	},

	methods: {
		load() {
			api.setting.get().then((res) => {
				switch(res.data.status) {
					case 200:
						this.hideList = res.data.content.hideList
						break
					case 500:
						this.pop('エラー', res.data.error[0])	
						break
				}
			}).catch()
		},
		changeBirth(birth) {
			this.birth = birth
		},
		upload() {
			var formData = new FormData();
			formData.append('icon', this.icon);
			//ContentTypeを変える
			const config = {
				header: {
					"Content-Type": "multipart/form-data",
				},
			};
			axios.post('/setting/send', formData, config).then(res => {
				switch(res.data.status) {	
					case 200:
						this.uploadOther()	
						break
					case 500:	
						this.pop(res.data.error[0])	
						break	
				}
			}).catch()
		},
		uploadOther() {
			api.setting.send({ remove: this.removeList }).then((res) => {
				switch(res.data.status) {
					case 200:
						this.pop('', res.data.content)
						break
					case 500:
						this.pop('エラー', res.data.error[0])
						break
				}
			}).catch()
		},
		pop(title, message) {
			this.$store.dispatch('setDialog', { title: title, message: message });
		},
	},
	created() {
		this.load()
	}
};
</script>