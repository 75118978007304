<template>
<!-- eslint-disable -->
	<div>
		<v-row style="margin: auto;">
			<v-col cols="5">
				<v-select label="西暦" :items="years" v-model="year" @change="changeBirth"  dense></v-select>
			</v-col>
			<v-col cols="3">
				<v-select label="月" :items="months" v-model="month" @change="changeBirth"  dense></v-select>
			</v-col>
			<v-col cols="3">
				<v-select label="日" :items="days" v-model="day" @change="changeBirth" dense></v-select>
			</v-col>
		</v-row>
	</div>
</template>

<script>
/*eslint-disable */
export default {
	name: 'BirthdayPicker',
	data(){
		return {
			year: '',
			month: '',
			day: '',
		}
	},
	methods: {
		resetDay() {
			this.day = ''
		},
		changeBirth() {
			this.$emit('changeBirth', this.$data)
		},
	},
	computed: {
		years() {
			const years = []
			for (let year = new Date().getFullYear(); year >= 1950; year--) {
				years.push(year)
			}
			return years
		},
		months() {
			const months = [...Array(12)].map((ele, i) => i + 1)
			return months
		},
		days() {
			let days = []
			if ((this.month === 2 && this.year % 4 === 0 && this.year % 100 !== 0) || (this.month === 2 && this.year % 400 === 0)) {
				days = [...Array(29)].map((ele, i) => i + 1)
			} else if (this.month === 2) {
				days = [...Array(28)].map((ele, i) => i + 1)
			} else if (this.month === 4 || this.month === 6 || this.month === 9 || this.month === 11) {
				days = [...Array(30)].map((ele, i) => i + 1)
			} else {
				days = [...Array(31)].map((ele, i) => i + 1)
			}
			return days
		},
	},
}
</script>
